html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
input[type="file"]:focus,
input[type="checkbox"]:focus
textarea,
.form-control:focus,
.uneditable-input:focus {
  border-color: #c4d4bc;
  box-shadow: none;
  outline: 0 none;
}
.login_container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: fixed;
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("../../assets/bg.jpg");
  background-size: cover;
  background-color: #e0e3df;
}
/* *************pas---------- */
.fil_pass{
  float: right;
}

.loginForm {
  position: absolute;
  width: 450px;
  max-height: 550px;
  background-color: #fff;
  padding: 20px 10px;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(202, 202, 202, 0.933);
  box-shadow: 0 2px 3px rgba(202, 202, 202, 0.933);
  transition: all 0.5s ease-in-out;
}
.p-viewer {
	z-index: 9999;
	position: absolute;
	top: 15%;
	right: 10px;
}
.pwd{
  position: relative;
}
.pass_eye{
  outline: none;
  border: none;
  background: transparent;
  float: right;
}
.loginForm > .logo {
  width: 150px;
  height: 150px;
  overflow: hidden;
  margin: 0 auto;
}
.loginForm .logo > img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
}
.login_btn {
  width: 100%;
  height: 50px;
  margin: 0 auto;
  display: block;
  font-size: 20px;
  background-color: #252525;
  color: #fff;
  border-radius: 1px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border: none;
  transition: all 0.2s linear;
  font-weight: 600;
}
.login_btn::after,
.login_btn::before {
  position: absolute;
  content: "";
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.login_btn::before {
  top: 0;
  left: 0;
  width: 150%;
  height: 100%;
  z-index: -1;
  background-color: #c6d6bf;
  -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.login_btn::after {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.login_btn:hover::before {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.login_btn:hover {
  color: #252525;
}

.form_field {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border-radius: 3px;
  border: 1px solid #c4d4bc;
}
.form_field:focus,
.form_field:active {
  border-color: #7f8c78;
}
.forget {
  text-decoration: none;
  color: #7f8c78;
}
.errors_{
  color: #d53343;
  user-select: none;
  padding: 0 10px;
  transition: all 0.5s ease-in-out;
}
.validated{
  border-color: #d53343 !important;
}

@media screen and (max-width: 600px) {
  .loginForm {
    width: 350px;
  }
}

/* Therapist Login */
.therapist_login_cont{
  display: block;
  max-width: 500px;
  height: 600px;
  margin: 0 auto;
  padding: 30px 0;
  text-align: center;
}
.loginForm > #logo2{
  width: 100px;
  height: 100px;
}
.login_title{
  position: absolute;
  left: 50%;
  top: 50px;
  transform: translateX(-50%);
  text-align: center;
}

/* ***************Tile************** */
.tile {
  width: 200px;
  font-size: 17px;
  font-weight: 400;
  color: white;
  text-align: center;
  background-color: #95a28f;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
}
.tile:hover {
  background-color: #323131;
  color: white;
  transition: 0.5s ease-in-out;
}
.start_stream_btn {
  width: 50%;
  height: 37px;
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: #50a151;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.start_stream_btn:hover {
  background-color: #428943;
}
.start_stream_btn:disabled {
  background-color: #bec9b9;
  cursor: auto;
}
/* *----------------Card Area-----------------* */

/* Modal CSS */

.ant-picker {
  width: 100% !important;
  border-color: transparent;
}
.ant-picker-focused {
  border-color: transparent;
  box-shadow: 0 0 0 transparent !important;
}
.duration {
  border: none !important;
}
#schedule_modal {
  min-width: 35vw !important;
  top: 50%;
}
.left {
  float: left;
}
.ryt {
  float: right !important;
  font-size: 18px;
}
.scn-btn {
  position: relative;
  display: block;
  width: 50%;
  height: 50px;
  font-size: 20px;
  background-color: #252525;
  color: #fff;
  border-radius: 5px;
  margin-top: 50px;
  z-index: 1;
  overflow: hidden;
  outline: none;
  border: none;
  transition: all 0.2s linear;
}

.scn-btn::after,
.scn-btn::before {
  position: absolute;
  content: "";
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.scn-btn::before {
  top: 0;
  left: 0;
  width: 150%;
  height: 100%;
  z-index: -1;
  background-color: #c6d6bf;
  -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.scn-btn::after {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.scn-btn:hover::before {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.scn-btn:hover {
  color: #252525;
}
.session_tile_head {
  color: white !important;
}
.add_schedule {
  float: right;
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  color: #fff;
  box-shadow: 0 0 0 transparent !important;
  border-radius: 50%;
  background-color: #3cb371;
  font-size: 20px;
  transition: all 0.2s ease-in-out;
}
.add_schedule:hover {
  background-color: #768271;
}
.add_schedule:active {
  box-shadow: 0 2px 3px #747474;
}
/* .mapped_data{
    overflow-x: scroll !important;
  } */
.mapped_data::-webkit-scrollbar {
  height: 7px;
}

.mapped_data::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #d1c6c6;
}

.dash_card_table::-webkit-scrollbar-thumb {
  background-color: #7f8f78;
  outline: 1px solid #95a28f;
}

.action_btn.approve {
  background-color: #198754;
  height: 40px;
  outline: none;
  border: none;
  font-size: 15px;
  color: white;
  width: 100px;
  margin-left: 25%;
  text-align: center;
}
.approve_icon {
  font-size: 25px;
}
.action_btn.deny {
  background-color: #d53343;
  height: 40px;
  outline: none;
  border: none;
  font-size: 15px;
  color: white;
  width: 100px;
  margin-left: 25%;
  text-align: center;
}
.schedule_card_ {
  width: 30%;
  padding: 20px;
}

.ant-picker-focused {
  border-color: transparent;
  box-shadow: 0 0 0 transparent !important;
}
.duration {
  border: none !important;
}
#schedule_modal {
  min-width: 35vw !important;
  top: 50%;
}
.left {
  float: left;
}
.ryt {
  float: right !important;
  font-size: 18px;
}
.scn-btn {
  position: relative;
  display: block;
  width: 50%;
  height: 50px;
  font-size: 20px;
  background-color: #252525;
  color: #fff;
  border-radius: 5px;
  margin-top: 50px;
  z-index: 1;
  overflow: hidden;
  outline: none;
  border: none;
  transition: all 0.2s linear;
}

.scn-btn::after,
.scn-btn::before {
  position: absolute;
  content: "";
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.scn-btn::before {
  top: 0;
  left: 0;
  width: 150%;
  height: 100%;
  z-index: -1;
  background-color: #c6d6bf;
  -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.scn-btn::after {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.scn-btn:hover::before {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.scn-btn:hover {
  color: #252525;
}
.session_tile_head {
  color: white !important;
}
.add_schedule {
  float: right;
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  color: #fff;
  box-shadow: 0 0 0 transparent !important;
  border-radius: 50%;
  background-color: #3cb371;
  font-size: 20px;
  transition: all 0.2s ease-in-out;
}
.add_schedule:hover {
  background-color: #768271;
}
.add_schedule:active {
  box-shadow: 0 2px 3px #747474;
}
.schedule_card {
  padding: 20px 20px 10px 20px;
}
.action_btn.approve {
  background-color: #198754;
  height: 40px;
  outline: none;
  border: none;
  font-size: 15px;
  color: white;
  width: 100px;
  margin-left: 25%;
  text-align: center;
}
.approve_icon {
  font-size: 25px;
}
.action_btn.deny {
  background-color: #d53343;
  height: 40px;
  outline: none;
  border: none;
  font-size: 15px;
  color: white;
  width: 100px;
  margin-left: 25%;
  text-align: center;
}
.schedule_card_ {
  width: 30%;
  padding: 20px;
}

/* .......tab---------------- */
.nav-tabs .nav-item.show .nav-link,
.nav-tabs {
  background-color: #c6d6bf;
  width: 50%;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs {
  border-radius: 10px;
  border: none;
  width: 50%;
  border-right: 5px;
  margin: 0 auto;
  background-color: transparent;
}
#justify-tab-example-tab-longer-tab.nav-link,
#justify-tab-example-tab-daily.nav-link {
  color: #252525;
}
#justify-tab-example-tab-longer-tab.nav-link.active {
  background-color: #95a28f;
  color: #fff !important;
}
#justify-tab-example-tab-All.nav-link {
  color: #252525;
}
#justify-tab-example-tab-longer-tab.nav-link.active,
#justify-tab-example-tab-daily.nav-link.active,
#justify-tab-example-tab-Upcoming.nav-link.active,
#justify-tab-example-tab-All.nav-link.active {
  background-color: #95a28f;
  color: #fff !important;
}
#justify-tab-example-tab-Upcoming.nav-link {
  color: #252525;
  border: none;
  border-radius: 5px;
}
#justify-tab-example-tab-Upcoming.nav-link.active {
  background-color: #95a28f;
}
.nav-tabs .nav-link {
  border-radius: 10px;
}
.nav-link {
  height: 50px;
  padding: 10px;
}
.des_ {
  width: 500px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.read-less {
  font-weight: 600;
  font-size: 12px;
  padding: 0 !important;
  margin-top: -35px !important;
  float: right;
  margin-right: 9px;
  cursor: pointer;
}
.name-screen {
  position: absolute;
  background-color: #2525257d;
  color: #fff;
  border-radius: 20px;
  cursor: auto;
  z-index: 99;
  user-select: none;
}
.name-screen.main_player {
  padding: 7px 12px;
  left: 20px;
  top: 20px;
}
.name-screen.side {
  padding: 5px 10px;
  left: 10px;
  top: 10px;
  font-size: 12px;
}
.info__ {
  padding: 10px;
  text-align: center;
  margin-top: 20px;
  font-size: 13px;
  color: #747474;
  user-select: none;
}
.not_acc_ {
  background-color: #b8e1b9;
  user-select: none;
  cursor: auto;
  text-align: center;
  padding: 5px 0;
}
.not_acc_:hover {
  background-color: #b8e1b9;
}

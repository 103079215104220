@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  font-family: "Outfit", sans-serif !important;
}
main {
  padding-top: 50px;
  padding-left: 15%;
  padding-right: 20px;
}
.main {
  padding: 40px 0;
}
.main > h1 {
  text-align: center;
}
.dash_card {
  position: relative;
  height: 150px;
  border-color: #e2e5e0;
  margin-top: 10px;
  padding: 10px 20px;
  overflow-x: hidden !important;
}
.dash_card > h3 {
  font-size: 25px;
  font-weight: 400;
  color: #747474;
}
.instructor {
  overflow: hidden !important;
}
.dash_card > h2 {
  color: #252525;
  font-weight: 700;
  font-size: 30px;
  line-height: 10px;
  transition: all 0.2s ease-in-out;
  user-select: none;
}
.dash_icon {
  position: absolute;
  width: 70px;
  height: 70px;
  right: 10px;
  bottom: 0;
  transition: all 0.2s ease-in-out;
}
.dash_icon > img {
  max-width: 100%;
  max-height: 100%;
  padding: 10px;
}
.dash_link {
  position: absolute;
  bottom: 10px;
  font-size: 25px;
  color: #252525;
  transition: all 0.2s ease-in-out;
  border: none;
  outline: none;
  background: transparent;
  z-index: 9;
}
.dash_card p {
  transition: all 0.2s ease-in-out;
}
.dash_card:hover > .dash_icon {
  height: 100%;
  width: 50%;
  right: 0;
  opacity: 0.5;
  filter: grayscale(70%);
  z-index: 0;
}
.dash_card:hover > h2 {
  z-index: 2;
  font-size: 4em;
  line-height: 0.5em;
  color: #5f9549;
}
.dash_card:hover > p {
  position: absolute;
  right: 30px;
  bottom: 5px;
  z-index: 2;
}
.edit_btn {
  cursor: pointer;
}
.user-profile {
  margin: 0px auto;
  text-align: center;
  width: 160px;
  height: 180px;
  border-radius: 50%;
  overflow: hidden;
}
.dash_card_table {
  width: 100%;
  padding: 10px 20px;
  color: #747474;
  user-select: none;
  font-size: 15px;
  overflow-x: scroll;
}
.dash_card_table > h3 {
  font-weight: 400;
}
.dash_card_table::-webkit-scrollbar {
  height: 7px;
}

.dash_card_table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #95a28f;
}

.dash_card_table::-webkit-scrollbar-thumb {
  background-color: #95a28f;
  outline: 1px solid #95a28f;
}
.social {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}
.social:hover {
  box-shadow: 0 2px 2px #747475;
}
.social a > img {
  max-width: 100%;
}

@media screen and (max-width: 1200px) {
  main {
    padding-left: 10%;
  }
  .dash_card {
    height: 120px;
  }
  .dash_card > h2 {
    font-size: 20px !important;
  }
  .dash_card > h3 {
    font-size: 16px;
  }

  .dash_card:hover > .dash_icon {
    height: 70%;
    width: 70%;
  }
  .dash_card:hover > h2 {
    font-size: 1rem;
    white-space: nowrap;
  }
  .dash_card > p {
    font-size: 12px;
  }
}

@media screen and (max-width: 991px) {
  main {
    padding-left: 50px;
  }
  .dash_card {
    height: 120px;
  }
  .dash_card > h2 {
    font-size: 30px;
  }
  .dash_card > h3 {
    font-size: 16px;
  }
  .dash_icon {
    height: 50px;
    width: 50px;
    bottom: 0;
    right: 0;
  }
  .dash_card:hover > .dash_icon {
    height: 70%;
    width: 70%;
  }
  .dash_card:hover > h2 {
    font-size: 0.5em;
    white-space: nowrap;
  }
  .dash_card > p {
    font-size: 12px;
  }
  .inst_card {
    overflow-y: scroll;
    overflow-x: hidden !important;
  }
}
.inst_card {
  overflow-y: scroll;
  overflow-x: hidden;
}
.therapist {
  position: relative;
  height: 240px;
  transition: all 0.5s ease-in-out;
  overflow-y: scroll !important;
  overflow-x: hidden;
}
.therapist > p {
  font-size: 15px;
  line-height: 1em;
  z-index: 2;
  font-weight: 600;
}
.therapist p > span {
  font-weight: 400;
}

.therapist p > i {
  position: relative;
  font-size: 15px;
  padding: 10px;
  line-height: 0;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.tooltip-therapist {
  visibility: hidden;
  position: absolute;
  width: 200px;
  height: auto;
  background-color: #fff;
  border: 1px solid #7777;
  right: -100%;
  top: 30px;
  padding: 20px 10px;
  z-index: 99;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.therapist p > i:hover .tooltip-therapist {
  visibility: visible;
  opacity: 1;
}
.spinner-border {
  margin: 0px auto !important;
  align-items: center;
  margin-top: 50px !important;
  width: 50px;
  height: 50px;
  border: 5px solid #95a28f;
}
.profile_pic_therapist {
  position: absolute;
  width: 90px;
  height: 90px;
  background-color: #eee;
  right: 10px;
  top: 10px;
  border-radius: 50%;
  overflow-x: hidden;
}
.profile_pic_therapist > img {
  max-width: 100%;
  max-height: 100%;
}
.therapist > .dash_icon {
  height: 90px;
  width: 90px;
}
.therapist:hover > .dash_icon {
  height: 150px;
  width: 150px;
  opacity: 0.4;
  filter: grayscale(0.7);
}
.pro-add {
  padding: 25px;
}
.material-icons {
  font-size: 18px;
}
.button-thera {
  position: absolute;
  font-weight: 500;
  border: none;
  width: 100px;
  border-radius: 6px;
  padding: 5px;
  background-color: #b7cbae;
  color: #252525;
  right: 10px;
  bottom: 10px;
  transition: all 0.2s ease-in-out;
}
.button-thera i {
  padding-right: 3px;
  font-size: 17px;
}
.button-thera:hover {
  background-color: #252525;
  color: white;
}
.pro-add p {
  color: red !important;
}
@media screen and (max-width: 1200px) {
  .therapist > h2 {
    font-size: 20px;
  }
  .therapist > p {
    font-size: 15px;
  }
  .profile_pic_therapist {
    width: 50px;
    height: 50px;
  }
}
@media screen and (max-width: 600px) {
  .pro-add {
    font-size: 12px;
  }
  .col-pro {
    display: block;
  }
}
.button_grp {
  position: relative;
  width: 150px;
  display: flex;
}
.req_btn {
  border: none;
  outline: none;
  padding: 5px 13px;
  border-radius: 5px 0 0 5px;
  width: 70%;
}
.stl_ {
  background-color: #c5d5bd;
  color: #252525;
  transition: all 0.2s ease-in-out;
}
.stl_:hover {
  background-color: #8b9a84;
}
.arrow_btn {
  outline: none;
  border: none;
  background-color: #c5d5bd;
  color: #252525;
  border-radius: 0 5px 5px 0;
  width: 20%;
  font-size: 12px;
}
.options {
  position: absolute;
  top: 40px;
  /* background-color: #fff; */
  width: 100%;
  z-index: 10;
}
.options > ul {
  text-decoration: none;
  list-style: none;
  padding: 0px;
}
.opt_ {
  outline: none;
  border: none;
  width: 50%;
  padding: 5px 0;
  transition: all 0.2s ease-in-out;
  border-radius: 6px;
  margin-left: 5px;
  color: #fff;
  font-size: 13px;
}
.opt_.dec_ {
  background-color: #f71042;
}
.opt_.dec_:hover {
  background-color: #d9113d;
}
.opt_.dec_:disabled {
  background-color: #fda1b5;
}
.opt_.acc_ {
  background-color: #338ed8;
  color: #fff;
}
.opt_.acc_:hover {
  background-color: #2a73af;
}
.opt_.acc_:disabled {
  background-color: #9bcbf2;
}
.ins_table {
  table-layout: fixed;
}
.ins_table #id__ {
  width: 10%;
}
.sched_date {
  margin-bottom: 0px;
  color: #747474;
  font-size: 13px;
  font-weight: 700;
}
#time_ {
  margin: 0px;
}
.user_list {
  margin: 10px 0;
  /* padding: 0px; */
  font-size: 15px;
}
.user_list li > p {
  font-weight: 500;
  margin: 0px;
}
.user_data {
  height: 200px;
  overflow-y: scroll;
}
.user_data::-webkit-scrollbar {
  width: 7px;
  height: 7px !important;
}
.user_data::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #f5f7fb;
}
.user_data::-webkit-scrollbar-thumb {
  background-color: #c4d4bc;
  outline: #95a28f;
}
.accepted_ {
  font-size: 13px;
  background-color: #b1c1aa;
  margin-top: 10px;
  text-align: center;
  width: 100px;
  padding: 5px 0;
  border-radius: 5px;
  color: #ffff;
  user-select: none;
}

.price{
    width: 180px !important;
}
/* Modal CSS */

.ant-picker {
    width: 100% !important;
    border-color: transparent;
  }
  .ant-picker-focused {
    border-color: transparent;
    box-shadow: 0 0 0 transparent !important;
  }
  .duration {
    border: none !important;
  }
  #schedule_modal {
    min-width: 35vw !important;
    top: 50%;
  }
  .left{
    float: left;
  }
  .ryt{
    float: right !important;
    font-size: 18px;
  }
  .scn-btn {
    position: relative;
    display: block;
    width: 50%;
    height: 50px;
    font-size: 20px;
    background-color: #252525;
    color: #fff;
    border-radius: 5px;
    margin-top: 50px;
    z-index: 1;
    overflow: hidden;
    outline: none;
    border: none;
    transition: all 0.2s linear;
  }
  
  .scn-btn::after,
  .scn-btn::before {
    position: absolute;
    content: "";
    -webkit-transition: all 0.35s ease-in-out 0s;
    -moz-transition: all 0.35s ease-in-out 0s;
    -ms-transition: all 0.35s ease-in-out 0s;
    -o-transition: all 0.35s ease-in-out 0s;
    transition: all 0.35s ease-in-out 0s;
  }
  .scn-btn::before {
    top: 0;
    left: 0;
    width: 150%;
    height: 100%;
    z-index: -1;
    background-color: #c6d6bf;
    -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
    transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transition: all 0.35s ease-in-out 0s;
    -moz-transition: all 0.35s ease-in-out 0s;
    -ms-transition: all 0.35s ease-in-out 0s;
    -o-transition: all 0.35s ease-in-out 0s;
    transition: all 0.35s ease-in-out 0s;
  }
  .scn-btn::after {
    opacity: 1;
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
    -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  }
  .scn-btn:hover::before {
    opacity: 1;
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
    -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  }
  .scn-btn:hover {
    color: #252525;
  }
  
  .add_schedule {
    float: right;
    width: 40px;
    height: 40px;
    border: none;
    outline: none;
    color: #fff;
    box-shadow: 0 0 0 transparent !important;
    border-radius: 50%;
    background-color: #3cb371;
    font-size: 20px;
    transition: all 0.2s ease-in-out;
  }
  .add_schedule:hover {
    background-color: #768271;
  }
  .add_schedule:active {
    box-shadow: 0 2px 3px #747474;
  }
  .schedule_card {
    padding: 20px 20px 10px 20px;
  }
 
  .action_btn.approve {
    background-color: #198754;
    height: 40px;
    outline: none;
    border: none;
    font-size: 15px;
    color: white;
    width: 100px;
    margin-left: 25%;
    text-align: center;
  }
  .action_btn.deny {
    background-color: #d53343;
    height: 40px;
    outline: none;
    border: none;
    font-size: 15px;
    color: white;
    width: 100px;
    margin-left: 25%;
    text-align: center;
  }
  .schedule_card_ {
    width: 30%;
    padding: 20px;
  }
 
